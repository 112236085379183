import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const scrollAnimMixin = {
  data() {
    return {
      scrollAnims: [],
      imgNum: 0,
      loadedImgNum: 0,
    };
  },
  mounted: function () {
    //  console.log("mounted")
    window.scrollTo(0, 0);
    this.setScrollAnimTrigger();
  },
  methods: {
    setScrollAnimTrigger: function () {
      let imgEle = document.querySelectorAll("img");
      // console.log(imgEle)
      this.imgNum = imgEle.length;
      if (this.imgNum <= 0) {
        this.setScrollAnim();
      } else {
        imgEle.forEach((img) => {
          if (img.complete) {
            this.loadedImgNum++;
            this.checkImgLoad();
          } else {
            img.onload = img.onerror = (e) => {
             img.onload = null;
             img.onerror = null;
             this.loadedImgNum++;
             this.checkImgLoad();
             if (e.type == "error") {
                console.log("Failed to load" + img.src);
              }
            };
          }
        });
      }
    },
    setScrollAnim: function () {
      let sections = gsap.utils.toArray(".js-scroll");

      sections.forEach((section) => {
        let tl = gsap.to(section, {
          opacity: 1,
          y: 0,
          duration: .7,
          ease: "power1.inOut",
          scrollTrigger: {
            trigger: section,
            start: "top 80%",
          },
        });
        this.scrollAnims.push(tl);
      });
    },
    checkImgLoad: function () {
      if (this.imgNum == this.loadedImgNum) {
        // console.log("setScrollAnim");
        gsap.registerPlugin(ScrollTrigger);
        this.setScrollAnim();
      }
    },
  },
  beforeDestroy() {
    // console.log(this.scrollAnims);
    this.scrollAnims.forEach((v) => {
      v.pause().kill();
    });
  },
}
export default scrollAnimMixin;